@tailwind base;
@tailwind components;
@tailwind utilities;

/* Poppins, Archivo */
@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@layer base {
	:root {
		--color-background: 240 240 247;
		--color-primary-lighter: 152 113 245;
		--color-primary-light: 145 107 234;
		--color-primary: 130 87 229;
		--color-primary-dark: 119 77 214;
		--color-primary-darker: 104 66 194;
		--color-secundary: 4 211 97;
		--color-secundary-dark: 4 191 88;
		--color-title-in-primary: 255 255 255;
		--color-text-in-primary: 212 194 255;
		--color-text-title: 50 38 77;
		--color-text-complement: 156 152 166;
		--color-text-base: 106 97 128;
		--color-line-in-white: 230 230 240;
		--color-input-background: 248 248 252;
		--color-button-text: 255 255 255;
		--color-box-base: 255 255 255;
		--color-box-footer: 250 250 252;
	}

	body {
		height: 100vh;
		background-color: rgb(var(--color-background));
		font-family: Poppins;
	}
}
